import 'moment/locale/az';

import moment from 'moment';

moment.locale('az');

export const years = [
  {
    id: 2020,
    name: '2020',
  },
  {
    id: 2021,
    name: '2021',
  },
];

export const months = [
  {
    id: 1,
    name: 'january',
    label: 'Yanvar',
  },
  {
    id: 2,
    name: 'february',
    label: 'Fevral',
  },
  {
    id: 3,
    name: 'march',
    label: 'Mart',
  },
  {
    id: 4,
    name: 'april',
    label: 'Aprel',
  },
  {
    id: 5,
    name: 'may',
    label: 'May',
  },
  {
    id: 6,
    name: 'june',
    label: 'Iyun',
  },
  {
    id: 7,
    name: 'july',
    label: 'Iyul',
  },
  {
    id: 8,
    name: 'august',
    label: 'Avqust',
  },
  {
    id: 9,
    name: 'september',
    label: 'Sentyabr',
  },
  {
    id: 10,
    name: 'october',
    label: 'Oktyabr',
  },
  {
    id: 11,
    name: 'november',
    label: 'Noyabr',
  },
  {
    id: 12,
    name: 'december',
    label: 'Dekabr',
  },
];
export const quarters = [
  {
    id: 1,
    name: 'first',
    label: 'RÜB I',
    months: [1, 2, 3],
  },
  {
    id: 2,
    name: 'second',
    label: 'RÜB II',
    months: [4, 5, 6],
  },
  {
    id: 3,
    name: 'third',
    label: 'RÜB III',
    months: [7, 8, 9],
  },
  {
    id: 4,
    name: 'fourth',
    label: 'RÜB IV',
    months: [10, 11, 12],
  },
];

export const re_percent = /^[0-9]{1,9}\.?[0-9]{0,4}$/;
export const re_amount = /^[0-9]{1,9}\.?[0-9]{0,4}$/;
export const re_paymentAmount = /^[0-9]{1,9}\.?[0-9]{0,2}$/;
export const re_quantity = /^[0-9]{1,5}$/;
export const currentYear = moment().year();
export const currentMonth = moment().month() + 1;
export const currentDay = moment().format('DD');

export const dateFormat = 'DD-MM-YYYY';
export const formatDate = date => moment(date).format(dateFormat);
export const fullDateTime = 'DD-MM-YYYY HH:mm';
export const fullDateTimeWithSecond = 'DD-MM-YYYY HH:mm:ss';
export const today = moment().format(dateFormat);
export const yesterday = moment()
  .subtract(1, 'day')
  .format(dateFormat);

export const todayWithMinutes = moment().format('DD.MM.YYYY HH:mm:ss');

export const thisWeekStart = moment()
  .startOf('week')
  .format(dateFormat);

export const thisWeekEnd = moment()
  .endOf('week')
  .format(dateFormat);

export const thisMonthStart = moment()
  .startOf('month')
  .format(dateFormat);

export const thisMonthEnd = moment()
  .endOf('month')
  .format(dateFormat);

export const thisYearStart = moment()
  .startOf('year')
  .format(dateFormat);

export const thisYearEnd = moment()
  .endOf('year')
  .format(dateFormat);

export const formItemSize = 'large';
export const defaultFormItemSize = 'default';

export const accountTypeOptions = [
  { id: 1, name: 'Nağd hesablar' },
  { id: 2, name: 'Bank hesabları' },
  { id: 3, name: 'Kredit kartları' },
  { id: 4, name: 'Digər' },
];

export const operationNames = {
  EXPENSE: 1,
  INCOME: 2,
  SPEND: 3,
  MONEY_TRANSFER: 4,
  SALARY: 6, // not 5
  INITIAL_BALANCE: 7,
};

Object.freeze(operationNames);

export const connectTypes = {
  Invite: 0,
  ReSend: 1,
  Connected: 2,
  ReInvite: 3,
};

export const userConnectTypesNames = {
  [connectTypes.Invite]: 'Dəvət et',
  [connectTypes.ReSend]: 'Dəvət göndərilib',
  [connectTypes.Connected]: 'Qoşulub',
  [connectTypes.ReInvite]: 'Yenidən dəvət et',
};

export const userSearchConnectOperationTypes = {
  Not_Connected: 1,
  Connected: 2,
  All: 3,
};

export const workerOperationTypes = {
  Entrance: 1,
  Vacation: 4,
  TimeOff: 3,
  Fire: 2,
  BusinessTrip: 5,
  SickLeave: 6,
  Appointment: 7,
};

export const reportHistoryOperationStatus = {
  Non_Deleted: 0,
  Deleted: 1,
};

export const getMonthNameByNumber = number =>
  moment(number, 'M').format('MMMM');

export const optionsMonth = [
  { id: 1, name: 'Yanvar' },
  { id: 2, name: 'Fevral' },
  { id: 3, name: 'Mart' },
  { id: 4, name: 'Aprel' },
  { id: 5, name: 'May' },
  { id: 6, name: 'Iyun' },
  { id: 7, name: 'Iyul' },
  { id: 8, name: 'Avqust' },
  { id: 9, name: 'Sentyabr' },
  { id: 10, name: 'Oktyabr' },
  { id: 11, name: 'Noyabr' },
  { id: 12, name: 'Dekabr' },
];

export const optionsYear = [
  { id: currentYear - 3, name: `${currentYear - 3}` },
  { id: currentYear - 2, name: `${currentYear - 2}` },
  { id: currentYear - 1, name: `${currentYear - 1}` },
  { id: currentYear, name: `${currentYear}` },
  { id: currentYear + 1, name: `${currentYear + 1}` },
  { id: currentYear + 2, name: `${currentYear + 2}` },
  { id: currentYear + 3, name: `${currentYear + 3}` },
];

export const featuresNamesByGroupKey = {
  common: 'Ümumi',
  msk: 'MSK',
  dashboard: 'Nəzarət paneli',
  users: 'İstifadəçilər',
  contact: 'Əlaqələr',
  projobs: 'İşçi axtarışı',
  stock: 'Anbar',
  sales: 'Ticarət',
  transaction: 'Maliyyə',
  hrm: 'HRM',
  order: 'Sifariş',
  call_center: 'Çağrı mərkəzi',
  report: 'Hesabat',
  task: 'Tapşırıqlar',
  columns: 'Sütunlar',
};
export const featuresNameByGroupKey = {
  tenant_requisites: 'İnfo',
  common: 'Ümumi',
  settings: 'MSK',
  dashboard: 'Nəzarət paneli',
  users: 'İstifadəçilər',
  contact: 'Əlaqələr',
  projobs: 'İşçi axtarışı',
  stock: 'Anbar',
  sales: 'Ticarət',
  prices_and_discounts: 'Qiymət və endirimlər',
  transaction: 'Maliyyə',
  hrm: 'HRM',
  order: 'Sifariş',
  call_center: 'Əlaqə mərkəzi',
  report: 'Hesabat',
  task: 'Tapşırıqlar',
  columns: 'Sütunlar',
  business_unit: 'Biznes blok',
  init_settings: 'İlkin qalıqlar',
  contact_center: 'Əlaqə mərkəzi',
  status: 'İcra statusları',
  messaging: 'Yazışma',
  import_excel: 'Excel-dən idxal',
  notifications: 'Bildirişlər',
};
export const minimumDate = '01-01-1900';
export const maximumDate = '31-12-2099';

export const contactTypes = [
  {
    id: 1,
    name: 'Fiziki şəxs',
  },
  {
    id: 2,
    name: 'Hüquqi şəxs',
  },
];

export const contactCategories = {
  1: {
    id: 1,
    name: 'Alıcı',
  },
  // 2: {
  //   id: 2,
  //   name: 'Error',
  // },
  4: {
    id: 4,
    name: 'Təchizatçı',
  },
  8: {
    id: 8,
    name: 'İstehsalçı',
  },
};

export const userStatusTypes = [
  {
    id: 0,
    name: 'Qoşulmayıb',
  },
  {
    id: 1,
    name: 'Qoşulub',
  },
];

export const productTypes = [
  {
    id: 2,
    name: 'Məhsul',
  },
  {
    id: 1,
    name: 'Xidmət',
  },
];

export const typeOfOperations = [
  { name: 'Alış', id: 1, key: 'purchase_invoice' },
  { name: 'Satış', id: 2, key: 'sales_invoice' },
  { name: 'Geri Alma', id: 3, key: 'return_from_customer_invoice' },
  { name: 'Geri Qaytarma', id: 4, key: 'return_to_supplier_invoice' },
  { name: 'Transfer', id: 5, key: 'transfer_invoice' },
  { name: 'Silinmə', id: 6, key: 'remove_invoice' },
  { name: 'Qaralama', id: 8, key: 'purchase_invoice' },
  { name: 'İdxal alışı', id: 10, key: 'import_purchase' },
];

export const paymentStatuses = [
  { name: 'Ödənilib', id: 3 },
  { name: 'Qismən ödənilib', id: 2 },
  { name: 'Açıq', id: 1 },
];

export const paymentType = [
  { name: 'Qaimə', id: 9, key: 'transaction_invoice_payment' },
  { name: 'Əməkhaqqı', id: 6, key: 'salary_payment' },
  { name: 'Transfer', id: 4, key: 'money_transfer' },
  { name: 'Təhtəl hesab', id: 12, key: 'transaction_balance_creation_payment' },
  { name: 'Təsisçi', id: 7, key: 'transaction_tenant_person_payment' },
  { name: 'Avans', id: 11, key: 'transaction_advance_payment' },
  { name: 'Xərclər', id: 8, key: 'transaction_expense_payment' },
];

export const accountTypes = [
  { id: 1, name: 'Nağd' },
  { id: 2, name: 'Bank transfer' },
  { id: 3, name: 'Kart' },
  { id: 4, name: 'Qarışıq' },
];

export const orderStages = {
  1: { name: 'New', id: 1, label: 'Yeni' },
  2: { name: 'Approved', id: 2, label: 'Təsdiq' },
  3: { name: 'In progress', id: 3, label: 'İcrada' },
  4: { name: 'Delivery', id: 4, label: 'Çatdırılma' },
  5: { name: 'Done', id: 5, label: 'Bitib' },
  6: { name: 'Reject', id: 6, label: 'İmtina' },
};

export const sortedStatuses = [
  { id: 1, name: 'draft', label: 'Qaralama', color: '#9b59b6', group: 1 },
  { id: 2, name: 'new', label: 'Yeni', color: '#3b4557', group: 1 },
  { id: 3, name: 'going', label: 'Icrada', color: '#f39c12', group: 1 },
  {
    id: 11,
    name: 'delivery',
    label: 'Çatdırılma/Qəbul',
    color: '#2980b9',
    group: 2,
  },
  { id: 10, name: 'done', label: 'Qəbul', color: '#16a085', group: 1 },
  { id: 12, name: 'done', label: 'Yolda', color: '#27ae60', group: 1 },
  {
    id: 13,
    name: 'delivery',
    label: 'Çatdırılma/Yolda',
    color: '#2980b9',
    group: 3,
  },
  {
    id: 9,
    name: 'delivery',
    label: 'Çatdırılma',
    color: '#2980b9',
    group: 1,
  },
  { id: 8, name: 'takeover', label: 'Təhvil', color: '#16a085', group: 1 },
  { id: 4, name: 'done', label: 'Bitib', color: '#27ae60', group: 2 },
  { id: 5, name: 'reject', label: 'Imtina', color: '#d35400', group: 1 },
  { id: 6, name: 'delete', label: 'Silinib', color: '#c0392b', group: 3 },
];
export const expeditorStages = [
  { id: 2, name: 'new', label: 'Yeni', color: '#3b4557', group: 1 },
  { id: 10, name: 'done', label: 'Qəbul', color: '#16a085', group: 1 },
  { id: 12, name: 'done', label: 'Yolda', color: '#27ae60', group: 1 },
  { id: 8, name: 'takeover', label: 'Təhvil', color: '#16a085', group: 1 },
  { id: 4, name: 'done', label: 'Bitib', color: '#27ae60', group: 2 },
];
export const visualStatuses = {
  1: { id: 1, name: 'draft', label: 'Qaralama', color: '#9b59b6', group: 1 },
  2: { id: 2, name: 'new', label: 'Yeni', color: '#3b4557', group: 1 },
  3: { id: 3, name: 'going', label: 'Icrada', color: '#f39c12', group: 1 },
  11: {
    id: 11,
    name: 'delivery',
    label: 'Çatdırılma/Qəbul',
    color: '#2980b9',
    group: 2,
  },
  10: { id: 10, name: 'done', label: 'Qəbul', color: '#16a085', group: 1 },
  12: { id: 12, name: 'done', label: 'Yolda', color: '#27ae60', group: 1 },
  13: {
    id: 13,
    name: 'delivery',
    label: 'Çatdırılma/Yolda',
    color: '#2980b9',
    group: 3,
  },
  9: {
    id: 9,
    name: 'delivery',
    label: 'Çatdırılma',
    color: '#2980b9',
    group: 1,
  },
  8: { id: 8, name: 'takeover', label: 'Təhvil', color: '#16a085', group: 1 },
  4: { id: 4, name: 'done', label: 'Bitib', color: '#27ae60', group: 2 },
  5: { id: 5, name: 'reject', label: 'Imtina', color: '#d35400', group: 1 },
  6: { id: 6, name: 'delete', label: 'Silinib', color: '#c0392b', group: 3 },
};

export const historyStatuses = {
  1: { id: 1, name: 'draft', label: 'Qaralama', color: '#9b59b6', group: 1 },
  2: { id: 2, name: 'new', label: 'Yeni', color: '#3b4557', group: 1 },
  3: { id: 3, name: 'going', label: 'Icrada', color: '#f39c12', group: 1 },
  4: {
    id: 4,
    name: 'going',
    label: 'İcrada/Qəbul',
    color: '#27ae60',
    group: 1,
  },
  5: {
    id: 5,
    name: 'going',
    label: 'İcrada/Bitib',
    color: '#27ae60',
    group: 1,
  },
  6: {
    id: 6,
    name: 'delivery',
    label: 'Çatdırılma',
    color: '#2980b9',
    group: 1,
  },
  7: {
    id: 7,
    name: 'delivery',
    label: 'Çatdırılma/Qəbul',
    color: '#2980b9',
    group: 1,
  },
  8: {
    id: 8,
    name: 'delivery',
    label: 'Çatdırılma/Yolda',
    color: '#2980b9',
    group: 1,
  },
  9: { id: 9, name: 'takeover', label: 'Təhvil', color: '#16a085', group: 1 },
  10: { id: 10, name: 'done', label: 'Bitib', color: '#16a085', group: 2 },
  11: { id: 11, name: 'reject', label: 'Imtina', color: '#d35400', group: 1 },
  12: { id: 12, name: 'delete', label: 'Silinib', color: '#c0392b', group: 3 },
  13: { id: 13, name: 'reject', label: 'Imtina', color: '#d35400', group: 1 },
};

export const orderStatuses = {
  1: { id: 1, name: 'draft', label: 'Qaralama', color: '#9b59b6', group: 1 },
  2: { id: 2, name: 'new', label: 'Yeni', color: '#3b4557', group: 1 },
  3: { id: 3, name: 'going', label: 'Icrada', color: '#f39c12', group: 1 },
  4: {
    id: 9,
    name: 'delivery',
    label: 'Çatdırılma',
    color: '#2980b9',
    group: 1,
  },
  5: {
    id: 11,
    name: 'delivery',
    label: 'Çatdırılma/Qəbul',
    color: '#2980b9',
    group: 1,
  },
  6: {
    id: 10,
    name: 'going',
    label: 'Qəbul',
    color: '#f39c12',
    group: 1,
  },
  7: {
    id: 13,
    name: 'delivery',
    label: 'Çatdırılma/Yolda',
    color: '#2980b9',
    group: 1,
  },
  8: {
    id: 12,
    name: 'delivery',
    label: 'Yolda',
    color: '#2980b9',
    group: 1,
  },
  9: { id: 8, name: 'takeover', label: 'Təhvil', color: '#16a085', group: 1 },
  10: { id: 4, name: 'done', label: 'Bitib', color: '#27ae60', group: 2 },
  11: { id: 5, name: 'reject', label: 'Imtina', color: '#d35400', group: 1 },
  12: { id: 6, name: 'delete', label: 'Silinib', color: '#c0392b', group: 3 },
};

export const orderTabs = [
  { id: 1, name: 'details', label: 'Ətraflı' },
  { id: 7, name: 'delivery', label: 'Çatdırılma' },
  { id: 2, name: 'invoiceContent', label: 'Daxil olan sifariş' },
  { id: 3, name: 'invoiceSent', label: 'Göndərilən sifariş' },
  { id: 4, name: 'invoiceAccepted', label: 'Təhvil alınan sifariş' },
  { id: 5, name: 'conversation', label: 'Mesajlar' },
  { id: 6, name: 'timeline', label: 'Tarixçə' },
];
export const orderDirections = {
  1: { id: 1, name: 'from', label: 'Daxil olan' },
  2: { id: 2, name: 'to', label: 'Xaric olan' },
};

export const internalCallTabs = [
  { id: 1, name: 'details', label: 'Ətraflı' },
  { id: 2, name: 'history', label: 'Tarixçə' },
  { id: 3, name: 'calls', label: 'Zənglər' },
];
export const expenseTypes = [
  { id: 1, label: 'Inzibati' },
  { id: 2, label: 'Əməliyyat' },
  { id: 3, label: 'Maliyyə' },
  { id: 4, label: 'Vergi' },
  { id: 6, label: 'Gömrük' },
  { id: 5, label: 'Digər' },
];

export const profitAndLossSummaryRows = [2, 6, 8, 10, 13];
export const profitAndLossReportsWithoutDetail = [1, 2, 6, 8, 10, 12, 13, 15];

export const balanceSheetWithoutDetail = [
  'Qiymətli kağızlar',
  'Öncədən ödənilmiş xərclər',
  'Digər cari aktivlər',
  'Torpaq, tikili, avadanlıqlar',
  'Qeyri maddi aktivlər',
  'Uzunmüddətli alacaqlar',
  'Digər uzunmüddətli aktvilər',
  'Bank kreditləri',
];
